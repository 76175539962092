import React from "react"

import * as styles from "./PersonLeader.module.scss"
import PlaceholderImage from "./PlaceHolderImage"
import Image from "./Image"
import { imageSizes } from "../../helpers/imgix"

const PersonLeader = (props) => (
    <div className={styles.PersonLeader}>
        <div className={styles.image}>
            {props.person_fields.image && <Image url={props.person_fields.image.sourceUrl} alt={props.person_fields.image.title} sizes={imageSizes.default} />}
        </div>
        <div className={styles.textContent}>
            <h3>{props.title}</h3>
            <span>{props.person_fields.role}</span>
            <span>
                <a href={"mailto:" + props.person_fields.email}>{props.person_fields.email}</a>
            </span>
        </div>
    </div>
)

export default PersonLeader
