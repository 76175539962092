import React from "react"

import * as styles from "./PersonLeaderList.module.scss"

const PersonLeaderList = (props) => (

    <div className={styles.PersonLeaderList}>
        {props.title &&
        <h3>{props.title}</h3>
        }
        <div className={styles.list}>
            {props.children}
        </div>
    </div>

)

export default PersonLeaderList