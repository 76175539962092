import React from "react"
import withPreview, { getContent } from "../apollo/preview"
import { contentPageWithTeamListPreview as PREVIEW_QUERY } from "../preview-queries/contentPageWithTeamListPreview"

// Data
import { graphql } from "gatsby"

// Components
import Layout from "../components/Global/Layout"
import Main from "../components/Shared/Main"
import Sidebar from "../components/Shared/Sidebar"
import Subnavigation from "../components/Shared/Subnavigation"
import CTA from "../components/Shared/CTA"
import PersonCTA from "../components/Shared/PersonCTA"
import Article from "../components/Shared/Article"
import Content from "../components/Shared/Content"
import ContentWithSidebar from "../components/Shared/ContentWithSidebar"
import ArticleHeader from "../components/Shared/ArticleHeader"
import HeroAreaSmall from "../components/Shared/HeroAreaSmall"
import PersonLeaderList from "../components/Shared/PersonLeaderList"
import PersonLeader from "../components/Shared/PersonLeader"
import ShareLinks from "../components/Shared/ShareLinks"
import { venueForPage } from "../helpers/venueForPage"
import { ResponsiveContext } from "grommet"
import SEOSettings from "../components/Shared/SEOSettings"

const TemplateContentPageWithTeamList = (props) => {
    const page = getContent(props, "page")

    const venue = venueForPage({ page: page, menus: props.data.menus })
    const venueChildPage = page.ancestors.nodes && page.ancestors.nodes.slice(-1)[0].pageTemplate === "home-page"
    const venueFirstChildPage = page.parent && page.parent.pageTemplate === "home-page"
    const ancestorsCount = page.ancestors && page.ancestors.length

    let superTitle
    if (venueChildPage && ancestorsCount === 2) {
        superTitle = page.ancestors.nodes.slice(-2)[0].title
    } else if (!venueChildPage && page.ancestors) {
        superTitle = page.ancestors.nodes.slice(-1)[0].title
    }

    return (
        <Layout venue={venue} currentLocation={props.path} title={page.title}>
            <SEOSettings entity={page} />
            <HeroAreaSmall title={superTitle} image={page.image.image}></HeroAreaSmall>

            <Main>
                <ContentWithSidebar>
                    <Content>
                        <ArticleHeader title={page.title} introduction={page.page_introduction.introduction} />

                        <ResponsiveContext.Consumer>
                            {(responsive) =>
                                (responsive === "smallMobile" || responsive === "mobile") && (
                                    <>{(page.children || page.parent) && !venueFirstChildPage && <Subnavigation sectionTitle={superTitle} page={page} />}</>
                                )
                            }
                        </ResponsiveContext.Consumer>

                        {page.content && (
                            <Article>
                                <ShareLinks url={page.link} excerpt={page.page_introduction.introduction} />
                                <div dangerouslySetInnerHTML={{ __html: page.content }}></div>
                            </Article>
                        )}

                        {page.team_fields?.teams &&
                            page.team_fields?.teams.map((team, index) => (
                                <PersonLeaderList key={`team-${index}`} title={team.teamName}>
                                    {team.teamMembers.map((member, index) => (
                                        <PersonLeader key={`member-${index}`} {...member} />
                                    ))}
                                </PersonLeaderList>
                            ))}
                    </Content>

                    <Sidebar>
                        <ResponsiveContext.Consumer>
                            {(responsive) =>
                                (responsive === "tablet" || typeof window !== "object" || responsive === "desktop" || responsive === "wide") && (
                                    <>{(page.children || page.parent) && !venueFirstChildPage && <Subnavigation sectionTitle={superTitle} page={page} />}</>
                                )
                            }
                        </ResponsiveContext.Consumer>

                        {page.sidebar_calls.sidebarCtas && page.sidebar_calls.sidebarCtas.map((cta) => <CTA key={cta.id} {...cta} />)}

                        {page.person_ctas.people && page.person_ctas.people.map((person) => <PersonCTA key={person.id} {...person} />)}
                    </Sidebar>
                </ContentWithSidebar>
            </Main>
        </Layout>
    )
}

export const pageQuery = graphql`
    query ContentPageWithTeamListQuery($databaseId: Int!) {
        menus: allWpMenu {
            results: nodes {
                ...PageMenusNode
            }
        }
        page: wpPage(databaseId: { eq: $databaseId }) {
            id
            ...PageContent
            ...PageSubnav
            ...SidebarCtas
            ...PersonCtas
            ...TeamsContent
            seo {
                metaDesc
                title
                opengraphTitle
                opengraphDescription
                metaKeywords
                metaRobotsNofollow
                metaRobotsNoindex
                opengraphImage {
                  sourceUrl
                }
              }
        }
        #wpgraphql {
        #    ...PageMenus
        #    page(id: $databaseId, idType: DATABASE_ID) {
        #        id
        #        #...PageContent
        #        #...PageSubnav
        #        #...Ctas
        #        #...PersonCtas
        #        ##...TeamsContent
        #    }
        #}
    }
`

export default withPreview({ preview: PREVIEW_QUERY })(TemplateContentPageWithTeamList)
